<template>
  <div
    v-if="historicalPriceState.show || followPrice"
    class="price-discount-intro"
    :style="compStyle"
  >
    <img
      v-if="historicalPriceState.show && historicalPriceState.icon"
      :src="historicalPriceState.icon"
      class="price-discount-icon"
    />
    <span>{{ text }}</span>
  </div>
</template>

<script>
/**
 * @file 价格优势说明标签组件
 */

import { inject, defineComponent, computed, onMounted } from 'vue'

import { hitHistoricalPrice } from '../../utils/index.js'

export default defineComponent({
  name: 'PriceDiscountIntro',
  props: {
    historicalPrice: {
      type: Array,
      default: () => [],
    },
    followPrice: {
      type: Object,
      default: () => null,
    },
  },
  setup (props) {
    const goodsInfo = inject('goodsInfo')
    const setAttrForAnalysis = inject('setAttrForAnalysis')
    
    const historicalPriceState = computed(() => {
      if (!props.historicalPrice?.length) {
        return { show: false }
      }
      return hitHistoricalPrice({
        salePrice: goodsInfo.value.salePrice,
        retailPrice: goodsInfo.value.retailPrice,
        historicalPrice: props.historicalPrice,
      })
    })

    const compStyle = computed(() => {
      const { show, ...historicalData } = historicalPriceState.value

      // 有值且不能是白色
      const hasBgColor = (bgColor) => {
        return  bgColor && !/ffffff/ig.test(bgColor)
      }
      if (show) {
        return {
          backgroundColor: historicalData.backgroundColor,
          color: historicalData.fontColor,
          padding: hasBgColor(historicalData.backgroundColor) ? '0 6px' : '',
        }
      }
      const config = props.followPrice
      if (config) {
        return {
          backgroundColor: config.backgroundColor,
          color: config.fontColor,
          padding: hasBgColor(config.backgroundColor) ? '0 6px' : '',
        }
      }
      return null
    })

    const text = computed(() => {
      const { show: showHistoricalPrice, ...historicalData } = historicalPriceState.value
      if (showHistoricalPrice) {
        return historicalData.labelLang || historicalData.labelLangEn
      }
      if (props.followPrice) {
        const config = props.followPrice
        return config.labelLang || config.labelLangEn
      }
      return ''
    })

    onMounted(() => {
      startSetAna({
        historicalPriceState,
        setAttrForAnalysis,
        followPrice: props.followPrice,
      })
    })

    return {
      compStyle,
      text,
      historicalPriceState,
    }
  },
})

const startSetAna = ({ historicalPriceState, setAttrForAnalysis, followPrice }) => {
  const { show: showHistoricalPrice, priceData = {} } = historicalPriceState.value
  if (showHistoricalPrice) {
    setAttrForAnalysis((el) => {
      if (historicalPriceState.value?.appTraceInfo) {
        el.setAttribute('data-historical-price', historicalPriceState.value.appTraceInfo)
      } else {
        const { high, low, adjust } = priceData
        const tagIds = [high.tagId, low.tagId, adjust.tagId]
        const labelLangEn = historicalPriceState.value.labelLangEn || ''
        el.setAttribute('data-historical-price', `show_lowest_price_${tagIds.join('_')}_${labelLangEn}`)
      }
    })
  } else if (followPrice) {

    setAttrForAnalysis((el) => {
      if (followPrice.appTraceInfo) {
        el.setAttribute('data-follow-price', followPrice.appTraceInfo)
      } else {
        const type = Number(followPrice.tagId) === 3598 ? 1 : 2
        const labelLangEn = followPrice.labelLangEn
        el.setAttribute('data-follow-price', `show_reduction_save_Price_${type}_${followPrice.tagId}_${labelLangEn}`)
      }
    })
  }
}
</script>

<style lang="less" scoped>
.price-discount-intro {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;

  .price-discount-icon {
    width: 12px;
    height: 12px;
    margin-right: 2px;
  }
}
</style>
