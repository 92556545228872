<template>
  <!-- 价格说明新样式，只针对到手价，参见：pageId=1460905528 -->
  <template v-if="showEstimatedNewStyle">
    <div
      :class="[
        'product-card-price-v2-bottom-label', 
        'product-card-price-v2-bottom-label_new', 
        isSheinClubePrice && 'clube-price-intro',
        isExclusiveEstimatedPrice && 'exclusive-price-intro'
      ]"
    >
      <div class="price-intro_estimated">
        {{ estimatedPrice }}
      </div>
      <span class="price-intro_line"></span>
      <p class="price-intro_text">
        {{ estimatedTextNewStyleLabel }}
      </p>
    </div>
  </template>
  <template v-else-if="priceLabelText">
    <div
      class="product-card-price-v2-bottom-label"
      :class="[color]"
    >
      <p class="label-text">
        {{ priceLabelText }} 
      </p>

      <!-- 倒计时，到手价时展示 -->
      <ProductCardCouponCountdown
        v-if="couponCountdown"
        :end-time="couponCountdown"
        :color="color"
      />
    </div>
  </template>
</template>

<script name="ProductCardPriceV2BottomLabel" setup>
import ProductCardCouponCountdown from './ProductCardCouponCountdown.vue'

import { inject, computed, onMounted } from 'vue'

const config = inject('config', () => ({}))
const language = inject('language', () => ({}))

const goodsInfo = inject('goodsInfo', () => ({}))
const finalPriceAndDiscountInfo = inject('finalPriceAndDiscountInfo', () => ({}))

const setAttrForAnalysis = inject('setAttrForAnalysis')

const estimatedTextNewStyleLabel = language.SHEIN_KEY_PC_31839 || 'with Coupon'

const { color, priceStateCollection, priceLabelText, finalPriceType } = finalPriceAndDiscountInfo.value || {}

const showEstimatedNewStyle = config.showNewStyleEstimated && priceStateCollection?.hasEstimatedPrice && !priceStateCollection?.finalABPriceType

const estimatedPrice = goodsInfo.value.estimatedPriceInfo?.estimatedPrice?.amountWithSymbol || ''

const isSheinClubePrice = computed(()=> {
  return goodsInfo.value.estimatedPriceInfo?.estimatedPriceType === 2 // 是付费会员到手价
})

const isExclusiveEstimatedPrice = computed(()=> {
  return goodsInfo.value.estimatedPriceInfo?.estimatedPriceType === 4 // 是s3会员到手价
})

// 倒计时，到手价时显示
const couponCountdown = computed(() => {
  const endTime = goodsInfo.value?.estimatedPriceInfo?.endTime
  
  if (endTime && config.showEstimatedLabelCountdown && finalPriceType === 'estimatedPrice') {
    return endTime
  }
  return ''
})

onMounted(() => {
  if (couponCountdown.value) {
    setAttrForAnalysis((el) => {
      el.setAttribute('data-discount-count-down', `list_price_time_tag_TagEstimated`)
    })
  }
})



</script>

<style lang="less" scoped>
/* stylelint-disable-next-line selector-class-pattern */
.product-card-price-v2-bottom-label {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  height: 14px;

  overflow: hidden;
  
  font-size: 12px;

  .label-text {
    .line-camp(1);

    text-overflow: ellipsis;
    text-align: left;

    word-break: break-all;
    height: 14px;
    line-height: 14px;
  }
}
.brown {
  color: @sui_color_club_rosegold_dark1;
}

.origin {
  color: @sui_color_discount;
}

.darkGold {
  color: #806208
}


/* stylelint-disable-next-line selector-class-pattern */
.product-card-price-v2-bottom-label_new {
  .line-camp(1);
  display: flex;
  height: 18px;
  line-height: 18px;
  padding: 4px 2px;
  box-sizing: content-box;
  align-items: center;
  justify-self: center;
  border-radius: 2px;

  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  word-break: break-all;
  vertical-align: middle;
  
  font-size: 16px;
  background-color: var(--promo_BG, #FFF1ED);
  color: @sui_color_discount;
  .price-intro_estimated {
    color: var(--sui_color_discount, #FA6338);
    font-weight: 700;
    white-space: nowrap;
  }
  .price-intro_line {
    width: 1px;
    height: 10px;
    margin: 0 4px;
    background-color: @sui_color_discount;
  }
  .price-intro_text {
    flex: 1;
    line-height: 1.3em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
  }
}
.clube-price-intro {
  background-color: var(--SheinClub_bg, #FFF5ED);
  color: @sui_color_club_rosegold_dark1;
  .price-intro_estimated {
    color: var(--sui_color_discount, @sui_color_club_rosegold_dark1);
  }
  .price-intro_line {
    background-color: @sui_color_club_rosegold_dark1;
  }
}

.exclusive-price-intro {
  background-color: #FFFBED;
  color: #806208;
  .price-intro_estimated {
    color: #806208;
  }
  .price-intro_line {
    background-color: #806208;
  }
}

// 处理ar站价格信息被反转问题
[mir=rtl] {
  .price-intro_estimated {
    direction: ltr;
  }
}

</style>
