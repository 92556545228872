<template>
  <div 
    class="product-card__goods-tag-content"
    :style="styles"
  >
    {{ tagData.labelLang }}
  </div>
</template>

<script name="ProductCardTitleTag" setup>

// eslint-disable-next-line vue/no-setup-props-destructure
const { tagData } = defineProps({
  tagData: {
    type: Object,
    default() {
      return {}
    }
  }
})

const styles = {
  backgroundColor: tagData?.backgroundColor,
  color: tagData?.fontColor,
}

</script>

<style lang="less" scoped>

.product-card__goods-tag-content {
  display: inline-block;
  padding: 0 2px;
  height: 16px;
  line-height: 16px;
  vertical-align: baseline;

  border-radius: 2px;

  background: var(--sui-color-new, #3CBD45);

  font-size: 12px;
  font-weight: 500;
  color: #EDFFEE;
}
</style>
