import { SIMetric } from 'public/src/pages/common/monitor/index.js'
import { getLocateLabelsAna } from '../analysis/locateLabelsAna'

/**
 * 是否是专题页
 * @returns {boolean}
 */
const isActivityPage = () => {
  return !!gbCommonInfo?.isActivity
}

export const isIE = () => {
  if (
    !!window.ActiveXObject ||
    'ActiveXObject' in window ||
    window.navigator.userAgent.indexOf('Edg') > -1
  ) {
    return true
  } else {
    return false
  }
}

export const controlItemLabels = (locateLabels, {
  config,
  flashSale,
  discountInfo
}) => {
  const isFlashSale = !config.hideDiscountBadge && flashSale && config.style?.discountLabel === 'flashSale'

  // 专题页跟闪购需要展示品牌、系列、大促角标的旧逻辑
  const needShowOldLabel = isActivityPage() || isFlashSale
  const locateLabelSet = new Set(Object.keys(locateLabels || {}).filter(key => Object.keys(locateLabels[key] || {}).length))

  const showDiscountLabel = config.showDiscountInMainImg && discountInfo?.show

  if (isFlashSale || showDiscountLabel || config.showFlashSaleLabelInMainImg) {
    // 闪购优先级最高
    locateLabelSet.delete('UPPER_LEFT_LABEL')
  }

  const locateLabelsAna = getLocateLabelsAna(locateLabels, locateLabelSet)

  return {
    needShowOldLabel,
    locateLabelsAna,
    visible: locateLabelSet.size > 0,
    upperLeftVisible: locateLabelSet.has('UPPER_LEFT_LABEL'),
    upperRightVisible: locateLabelSet.has('UPPER_RIGHT_LABEL'),
    lowerLeftVisible: locateLabelSet.has('LOWER_LEFT_LABEL'),
    lowerRightVisible: locateLabelSet.has('LOWER_RIGHT_LABEL'),
  }
}

export const TRIGGER_EVENT_MAP = {
  'AddCarFailFavSuccess': '1',
  'AddCarFailFavFail': '2',
  'AddCarSuccessFavSuccess': '3',
  'AddCarSuccessFavFail': '4',
  'NotJudged': '5',
}

/**
 * @description abt解析给到推荐的ruleId
 * @param {*} listAbtResult abtList
 * @param {*} triggerEvent 触发事件
 * @returns 
 */
export const feedbackAbtToRuleId = (listAbtResult, triggerEvent) => {
  const ruleId = {}
  const index = TRIGGER_EVENT_MAP[triggerEvent]
  const abt = listAbtResult?.RealtimeFeedback?.p || {}
  
  Object.keys(abt).forEach((key) => { 
    const arr = abt[key].split('&')

    if (arr.length <= 1) {
      ruleId[key] = abt[key]
    } else {
      arr.forEach(str => {
        const [k, v] = str.split('=')
        if (k === index) {
          ruleId[key] = v
        }
      })
    }
  })

  return ruleId
}

/**
 * 腰带与价格之间的控制逻辑
 * @param {Object} config abt相关的配置
 * @param {Object} param1 
 * @returns {{
*  beltLabel: false | Object;
*  showBestDeal: boolean;
*  followLabelVisible: boolean;
*  isPromoPrice: boolean;
* }}
*/
export const controlBeltPrice = (config, {
  isSoldOut = false,
  pretreatInfo,
  labelsFromKey
}) => {
  const { showBeltLabel } = config || {}
  const beltLabel = showBeltLabel && !isSoldOut && pretreatInfo?.[labelsFromKey]?.beltLabel
  const showBestDeal = !!pretreatInfo?.showBestDeal // 保留款腰带
  let followLabelVisible = pretreatInfo.followLabelVisible && !isSoldOut // 跟价款腰带
  let isPromoPrice = false // 价格是否展示促销状态

  if (beltLabel || showBestDeal) {
    // 保留款腰带>CCC配置的促销腰带>跟价款腰带
    followLabelVisible = false
  }
  if (followLabelVisible || showBestDeal) {
    isPromoPrice = true
  }

  return {
    beltLabel,
    showBestDeal,
    followLabelVisible,
    isPromoPrice,
  }
}

// 历史低价取值
export const hitHistoricalPrice = ({ salePrice, retailPrice, historicalPrice }) => {
  const price = Number(salePrice?.usdAmount || retailPrice.usdAmount)
  
  let data = null
  if (historicalPrice?.length && historicalPrice[0]?.appTraceInfo) {
    data = historicalPrice[0]
  } else {
    data = (historicalPrice || []).find(item => {
      const { high = {}, low = {}, adjust = {} } = item.priceData || {}
      const adjustNum = Number(adjust.tspVal)
      if (adjustNum <= 1) {
        return false
      }
      if (price < Number(high.tspVal) && price <= Number(low.tspVal)) {
        return true
      }
      return false
    })
  }

  return data ? {
    show: true,
    ...data,
  } : {
    show: false,
  }
}



/**
 * 适用于 服务标签区域 分布式筛选
 * 计算一个行内的dom宽度，如果超过最大宽度则不显示
 * @param {*} width 最大宽度
 * @param {*} domList 需要计算宽度的dom列表
 * @returns 返回可以放进去的dom的index
 */
export const checkSlicingDomList = (width, domList = []) => {
  if (!domList.length) return []
  
  let widthCumulativeValue = width

  const marginRightString = window.getComputedStyle(domList[0])?.marginRight ?? ''
  const domMarginRight = Math.ceil(marginRightString.replace('px', ''))

  const showIndexList = []

  ;[...domList].forEach((element, index) => {
    const elWidth = element.offsetWidth + domMarginRight

    const surplus =  widthCumulativeValue - elWidth

    // 当还有宽度时则插入
    // 或者
    // 最后一个元素去掉margin能够完全显示
    if (
      surplus >= 0 || 
      (surplus <= 0 && surplus >= -domMarginRight)
    ) {
      showIndexList.push(index)
      widthCumulativeValue -= elWidth
    }
  })

  return {
    showIndexList,
    remainingWidth: widthCumulativeValue
  }
}

/**
 * 累加计数监控指标上报
 * @param {string} metricName 指标名称
 * @param {object} tags 指标维度
 * @param {string} message 日志信息 
 */

export const reportMetricCount = ({ metricName, tags, message }) => {
  SIMetric.metricCount({
    metric_name: metricName,
    tags,
    message,
  })
}

// 计算字符长度
export const exportgetByteLength = (text) => {
  if (TextEncoder) {
    const encoder = new TextEncoder()
    const encodedText = encoder?.encode?.(text)
    return encodedText?.length
  } else {
    let byteLength = 0
    for (let i = 0; i < text.length; i++) {
      const charCode = text.charCodeAt(i)
      if (charCode <= 0x7F) {
        byteLength += 1
      } else if (charCode <= 0x7FF) {
        byteLength += 2
      } else if (charCode <= 0xFFFF) {
        byteLength += 3
      } else {
        byteLength += 4
      }
    }
    return byteLength
  }
}
