<template>
  <div 
    v-if="unitDiscountString"
    :class="containerClass"
  >
    <span 
      v-if="prefixText.text" 
      class="prefix-text"
    >{{ prefixText.text }}</span>
    <span class="discount-text">{{ unitDiscountString }}</span>
  </div>
</template>

<script name="DiscountLabel" setup>
import { inject, computed, onMounted } from 'vue'
import { hitHistoricalPrice, exportgetByteLength } from '../../utils/index.js'
import { views } from '../../utils/constant'

const labelsFromKey = inject('labelsFromKey')
const goodsInfo = inject('goodsInfo')
const constantData = inject('constantData')
const language = inject('language')
const config = inject('config')
const setAttrForAnalysis = inject('setAttrForAnalysis')

// const props = defineProps({
//   position: {
//     type: String,
//     default: 'price'
//   }
// })

const finalPriceAndDiscountInfo = inject('finalPriceAndDiscountInfo', () => ({}))

const { color, finalPriceType, finalDiscount, priceStateCollection } = finalPriceAndDiscountInfo.value || {}
const { showDiscountLabelCountdownStyle, moduleFrom } = config || {}

const unitDiscountString = computed(() => {
  const cssRight = constantData?.GB_cssRight
  let unitDiscountString = finalDiscount?.unitDiscountString || ''

  if (!unitDiscountString) return ''

  if (cssRight) {
    unitDiscountString = unitDiscountString.replace(/(-?)(\d+(\.\d+)?)(%)/, '$4$2$1')
  }

  // buybox场景下，不展示普通折扣标签，只展示倒计时折扣标签
  const onlyShowPrefixDiscountLabel = ['buybox'].includes(moduleFrom)
  if (onlyShowPrefixDiscountLabel) {
    const { estimatedPriceInfo } = goodsInfo.value || {}

    if (estimatedPriceInfo?.endTime && showDiscountLabelCountdownStyle) {
      return unitDiscountString
    }
    return ''
  } else {
    return unitDiscountString
  }

})

const colorClassName = computed(()=> {
  return color
})

const containerClass = computed(() => {
  let styleClass = ''

  const discountLabelStyle = config.style?.discountLabel || ''

  if (prefixText.value.type) {
    styleClass = 'prefix-text-discount'

    if (!discountLabelStyle) styleClass += ' bg'
  }

  return [
    'product-card__discount-label',
    colorClassName.value,
    styleClass,
    discountLabelStyle
  ]
})

// 历史低价
const historicalPriceState = computed(() => {
  const { showPriceDiscountDescInDiscountLabel } = config || {}

  const historicalPrice = goodsInfo.value[labelsFromKey]?.historicalPrice
  if (!historicalPrice?.length || !showPriceDiscountDescInDiscountLabel) {
    return { show: false }
  }

  const historicalData = hitHistoricalPrice({
    salePrice: goodsInfo.value.salePrice,
    retailPrice: goodsInfo.value.retailPrice,
    historicalPrice: historicalPrice,
  })

  return historicalData
  
})

// 前缀
const prefixText = computed(() => {
  let res = {
    type: '',
    text: ''
  }
  const hasMaxLength = labelsFromKey === views.LIST_PC_STANDARD_VIEW

  const { estimatedPriceInfo } = goodsInfo.value || {}
  const lastDayText = language.SHEIN_KEY_PC_32957 || 'Last day'
  const lastDayTextLength = exportgetByteLength(lastDayText)

  const historicalPriceTextLength = exportgetByteLength(historicalPriceState.value.labelLang ?? '')

  if (!(hasMaxLength && lastDayTextLength > 22) && finalPriceType === 'estimatedPrice' && !priceStateCollection?.hasFlashPromotion && estimatedPriceInfo?.endTime && showDiscountLabelCountdownStyle) {
    res = {
      type: 'estimatedCountDown',
      text: lastDayText
    }
  } else if (!(hasMaxLength && historicalPriceTextLength > 22) && historicalPriceState.value?.show && historicalPriceState.value.labelLang) {
    res = {
      type: 'historical',
      text: historicalPriceState.value.labelLang
    }
  }

  return res
})

onMounted(() => {
  setAttrForAnalysis((el) => {
    if (unitDiscountString.value && prefixText.value.type === 'estimatedCountDown') {
      el.setAttribute('data-discount-count-down', `list_price_time_tag_TagTitle`)
    } else if (unitDiscountString.value && prefixText.value.type === 'historical') {

      if (historicalPriceState.value?.appTraceInfo) {
        el.setAttribute('data-historical-price', historicalPriceState.value?.appTraceInfo)
      } else {
        const { high, low, adjust } = historicalPriceState.value?.priceData || {}
        const tagIds = [high.tagId, low.tagId, adjust.tagId]
        const labelLangEn = historicalPriceState.value.labelLangEn || ''
        el.setAttribute('data-historical-price', `show_lowest_price_${tagIds.join('_')}_${labelLangEn}`)
      }
    }
  })
})


</script>

<style lang="less" scoped>

.product-card__discount-label {
  direction: ltr /*rtl:ignore*/;

  display: flex;
  align-items: center;

  padding: 0 2px 0 3px;
  height: 16px;
  line-height: 14px;

  border: 1px solid;
  border-radius: 2px;

  font-size: 12px;

  // Todo: 优化样式，使用css变量
  &.origin {
    border-color: @sui_color_discount;
    color: @sui_color_discount;

    .prefix-text::after {
      background: @sui_color_discount;
    }
  }

  // 如果是会员相关的，折扣样式（字体颜色这些存在变化）
  &.brown {
    border-color: @sui_color_club_rosegold_dark1;
    color: @sui_color_club_rosegold_dark1; 

    .prefix-text::after {
      background: @sui_color_club_rosegold_dark1; 
    }
  }
  &.darkGold {
    border-color: #806208;
    color: #806208;

    .prefix-text::after {
      background: #806208;
    }
  }

  // 背景色主题
  // 目前只有到手价倒计时折扣标签用
  &.bg {
    background: @sui_color_discount;
    border-color: @sui_color_discount;
    color: @sui_color_white;

    .prefix-text::after {
      background: rgb(255, 255, 255);
    }
  
    
    // &.origin {
    //   background: @sui_color_discount;
    //   color: @sui_color_white;
    //   &.prefix-text::after {
    //     background: @sui_color_discount;
    //   }
    // }
    // &.brown {
    //   background: @sui_color_club_rosegold_dark1;
    //   color: @sui_color_white;
    //   &.prefix-text::after {
    //     background: @sui_color_club_rosegold_dark1;
    //   }
    // }
    // &.darkGold {
    //   background: #806208;
    //   color: @sui_color_white;
    //   &.prefix-text::after {
    //     background: #806208;
    //   }
    // }

  }

  .prefix-text {
    position: relative;
    padding-right: 5px;

    overflow: hidden;
    text-overflow: ellipsis;

    white-space: nowrap;

    &::after {
      content: '';
      position: absolute;
      right: 2px;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 8px;
      background: @sui_color_discount;
      opacity: .5;
    }
  }

  .discount-text {
    flex: 1 0 auto;
  }
  

  &.prefix-text-discount {
    max-width: 100%;
    overflow: hidden;

    display: flex;
    align-items: center;

    padding: 0 1px;
    height: 18px;
    line-height: 16px; 
    
    font-size: 14px;
  }
}
</style>
