<template>
  <div 
    v-if="beginTime" 
    class="countdown-time-container"
  >
    <sui_icon_countdown_outline_normal_10px 
      size="12px"
      :color="color"
    />
    <div
      class="countdown-time"
      v-html="timeTips"
    ></div>
  </div>
</template>

<script name="ProductCardCouponCountdown" setup>
import { sui_icon_countdown_outline_normal_10px } from '@shein-aidc/icon-vue3'

/**
 * @file 价格标签倒计时组件
 */

import { ref, computed, onMounted, onUnmounted } from 'vue'

import { CountDown, debounce } from '@shein/common-function'

const emit = defineEmits([
  'countDownOver'
])

const props = defineProps({
  /**
   * @param {String} 结束时间 时间戳
   */
  endTime: {
    type: [Number, String],
    required: true,
    default: ''
  },
  color: {
    type: String,
    default: '#FA6338'
  }
})
const colorSet = new Map([
  ['brown', '#873C00'],
  ['origin', '#FA6338'],
  ['darkGold', '#806208']
])
const color = computed(() => {
  return colorSet.get(props.color) || props.color
})

const countTimer = ref(new CountDown())
const beginTime = ref(null)

const timeTips = computed(() => {
  const { H, M, S } = countTimer.value?.timeObj || {}
  let spanTxt = txt => `<span>${txt}</span>`
  return spanTxt(H) + ':' + spanTxt(M) + ':' + spanTxt(S)
})

const init = () => {
  // 倒计时开始时间
  beginTime.value = props.endTime * 1000 - new Date().getTime()
  if (beginTime.value <= 0) {
    beginTime.value = 0
    emit('countDownOver')
  } else {
    // 初始化倒计时
    countTimer.value.start({
      seconds: beginTime.value / 1000,
      endFunc: debounce({
        func: () => {
          beginTime.value = 0
          emit('countDownOver')
        }
      })
    })
  }
}

onMounted(() => {
  init()
})

onUnmounted(() => {
  countTimer.value?.clear()
})

</script>

<style lang="less" scoped>
.countdown-time-container {
  display: flex;
  align-items: center;

  height: 14px;

  padding-left: 2px;

  font-size: 12px;
}
.countdown-time {
  display: flex;
  align-items: center;

  height: 14px;
  line-height: 14px;
}
</style>
