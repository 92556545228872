<template>
  <div
    class="normal-price-ctn__prices"
    :class="priceCtnCls"
    :style="{'height': config.hideDiscountLabel ? priceCtnStyle.height : ''}"
  >
    <div class="normal-price-ctn__sale-price-wrapper">
      <!-- 促销icon集合 -->
      <template v-if="promotionIconList.length > 0 && !config.hidePromotionIcon">
        <i
          v-for="(promoIcon, i) in promotionIconList"
          :key="i"
          :class="[
            promoIcon,
            'sh_pc_sui_icon',
          ]"
        ></i>
      </template>

      <!-- 售价 -->
      <span 
        v-if="salePriceInfo.finalPrice"
        :class="salePriceInfo.classList"
        :style="priceStyle.salePrice"
      >
        <CamelCasePrice
          :price="salePriceInfo.finalPrice"
          :max-size="18"
          :min-size="12"
          :is-camel-case-price="config.isCamelCasePrice"
          :data-note="supportVueDebug ? '售价 取salePrice' : ''"
        />
      </span>

      <ProductCardS3VipSalePriceIcon 
        v-if="showExclusiveIcon"
        :goods-info="goodsInfo"
      />

      <SuggestedSalePriceContainer
        v-if="salePriceInfo.priceRight.show"
        :goods-info="goodsInfo"
        :price-data="salePriceInfo.priceRight"
      />
    </div>

    <!-- 新划线价（合规价） -->
    <SuggestedSalePrice
      v-if="salePriceInfo.priceBottom.show"
      :style="{ maxWidth: '100%', minWidth: 0 }"
      :value="salePriceInfo.priceBottom.priceData"
      :label-text="salePriceInfo.priceBottom.labelText"
      :flip-text="salePriceInfo.priceBottom.flipText"
      :price-delete-style="salePriceInfo.priceBottom.priceDeleteStyle"
    />

    <!-- 建议零售价 RRP价
        竖版商卡 为保持加车按钮居中，合规价显示时此处不显示
        横版不影响 -->
    <SuggestedSalePriceContainer
      v-if="salePriceInfo.priceBottomRrp.show"
      :goods-info="goodsInfo"
      :price-data="salePriceInfo.priceBottomRrp"
    />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import CamelCasePrice from 'public/src/pages/components/CamelCasePrice.vue'
import SuggestedSalePrice from './SuggestedSalePrice.vue'
import SuggestedSalePriceContainer from './SuggestedSalePriceContainer.vue'
// utils
import { views } from '../../utils/constant.js'

export default {
  name: 'ProductCardNormalPrice',
  components: {
    SuggestedSalePrice,
    CamelCasePrice,
    SuggestedSalePriceContainer,
    ProductCardS3VipSalePriceIcon: defineAsyncComponent(
      () => import(/* webpackChunkName: "style-gallery" */'./ProductCardS3VipSalePriceIcon.vue'),
    )
  },
  inject: ['labelsFromKey', 'goodsInfo', 'finalPriceAndDiscountInfo', 'config', 'supportVueDebug'],
  props: {
    soldOut: {
      type: Boolean,
      default: true,
    },
    beltLabel: {
      type: [Object, Boolean],
      default: () => ({})
    }
  },
  data() {
    return {}
  },
  computed: {
    priceCtnStyle() {
      return this.config.style?.priceCtn || {}
    },
    priceCtnCls () {
      return {
        'normal-price-ctn__prices_over-hidden': this.config.hideDiscountLabel,
        'normal-price-ctn__prices_discount': this.showDiscountLabel
      }
    },
    /** the icon next to price **/
    promotionIconList() {
      let list = []

      const { suggestedSalePriceData } = this.finalPriceAndDiscountInfo.value || {}
      if(
        this.config.hideIconBeforePrice 
        || 
        (suggestedSalePriceData?.type && (!suggestedSalePriceData?.show30DaysLowestPrice))
      ) {
        return []
      }

      const { color, finalPriceType } = this.finalPriceAndDiscountInfo.value || {}
      const isBrownColor = color === 'brown'

      let couponCountdown = ''
      const endTime = this.goodsInfo.value?.estimatedPriceInfo?.endTime
  
      if (endTime && this.config.showEstimatedLabelCountdown && finalPriceType === 'estimatedPrice') {
        couponCountdown = endTime
      }

      const mixPromotionInfo = this.goodsInfo.value.pretreatInfo?.mixPromotionInfo || []

      mixPromotionInfo?.forEach?.((i) => {
        if (!i.icon || (couponCountdown && [31, 3].includes(i.typeId))) return

        // app专属图标无需展示
        if (i.typeId !== 8) {
          if (i.typeId == 10) {
            if (this.beltLabel?.component == 'flash_purchase_belt') return
            // 闪电标（1: 普通闪购, 4: 分人群定价专区闪购, 5: 不分人群定价专区闪购）
            if (([1, 5].includes(+i.flash_type) || +i.flash_type === 4 && this.flashZonePriceWithSymbol)) {
              list.push(i.icon)
            }
            return
          }
          // <!-- 针对限时折扣处理icon 颜色样式 -->
          if(isBrownColor && [31, 3].includes(i.typeId)) {
            list.push('sh_pc_sui_icon_time_16px_clube')
          }else {
            list.push(i.icon)
          }
        }
      })
      

      return list.length > 0 ? list.slice(0, 1) : []
    },
    showExclusiveIcon() {
      const { finalPriceType } = this.finalPriceAndDiscountInfo.value || {}
      
      const { estimatedPriceType } = this.goodsInfo.value.estimatedPriceInfo ?? {}
      const isExclusiveEstimatedPrice = estimatedPriceType === 4
      
      return finalPriceType === 'exclusivePrice' || (finalPriceType === 'estimatedPrice' && isExclusiveEstimatedPrice)
    },
    priceStyle() {
      const style = {
        salePrice: {},
        retailPrice: {}
      }
      const { salePriceFontSize, salePriceColor } = this.config.style || {}

      const { priceStateCollection } = this.finalPriceAndDiscountInfo.value || {}

      // 如果当前商卡命中新到手价的展示样式
      const satisfiedNewStyleEsat = this.config.showNewStyleEstimated && priceStateCollection?.hasEstimatedPrice && !priceStateCollection?.finalABPriceType

      // 如果当前商卡命中新到手价的展示样式
      let newStyleEsatFontSize = satisfiedNewStyleEsat ? '14px' : ''
      let newStyleEsatFontWeight = satisfiedNewStyleEsat ? 400 : ''


      salePriceColor && (style.salePrice.color = salePriceColor)
      salePriceFontSize && (style.salePrice.fontSize = salePriceFontSize)
      newStyleEsatFontSize && (style.salePrice.fontSize = newStyleEsatFontSize)
      newStyleEsatFontWeight && (style.salePrice.fontWeight = newStyleEsatFontWeight)

      return style
    },
    showDiscountLabel() {
      const { isSpecialDeSuggested } = this.finalPriceAndDiscountInfo.value || {}
      
      return this.config.showDiscountLabelAfterPrice && !isSpecialDeSuggested
    },
    flashZonePriceWithSymbol() {
      return this.goodsInfo.value.flashZonePromotion?.price?.amountWithSymbol || ''
    },
    salePriceInfo() {
      const classList = ['normal-price-ctn__sale-price']
      const classTypeMap = {
        origin: 'normal-price-ctn__sale-price_promo', // 促销色
        brown: 'normal-price-ctn__sale-price_sheinclub', // 付费会员色
        darkGold: 'normal-price-ctn__sale-price_s3vip' // 专属色
      }

      const { color, finalPrice, priceRight, priceBottom, priceBottomRrp } = this.finalPriceAndDiscountInfo.value || {}

      classList.push(classTypeMap[color])


      return {
        finalPrice,
        classList,
        priceRight, 
        priceBottom,
        priceBottomRrp: priceBottomRrp?.show && (!priceBottom?.show || this.labelsFromKey === views.LIST_PC_BROADWISE_VIEW) ? priceBottomRrp : {}
      }
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>

.normal-price-ctn {
  .flexbox();
  .space-between();
  .align-center();
  position: relative;
}
.normal-price-ctn {
  &__prices {
    max-width: 100%;
    
    .flexbox();
    flex-direction: column;
    align-items: flex-start;

    min-width: 0;
    .normal-price-ctn__sale-price-wrapper {
      max-width: 100%;
      align-items: baseline;

      /* stylelint-disable-next-line selector-class-pattern */
      &>.sh_pc_sui_icon {
        align-self: center;
      }
    }

    &.flex-row {
      flex-direction: row;
      .align-center();
    }

    flex-wrap: nowrap;
    
    margin-right: 2px;
    &_gap {
      padding-right: 10px;
    }
    /* stylelint-disable selector-class-pattern */
    .sh_pc_sui_icon_apponly_20px_promo,
    .sh_pc_sui_icon_time_16px_clube,
    .sh_pc_sui_icon_time_16px_promo {
      margin-right: 4px;
    }
    &_discount {
      line-height: 21px;
      .normal-price-ctn__sale-price {
        font-size: 16px;
      }
      .normal-price-ctn__retail-price {
        margin-left: 2px;
      }
    }
    &_over-hidden {
      height: 18px;
      line-height: 18px;
      overflow: hidden;
    }
    .shein-club-discount_small {
      margin-right: 4px;
      margin-top: 0;
    }
  }
  &__sale-price-wrapper {
    .flexbox();
    .align-center();
  }
  &__sale-price,
  &__retail-price,
  &__discount-label {
    margin-right: 4px;
  }
  &__sale-price {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: #222;
    &_promo {
      color: @sui_color_discount;
    }
    &_sheinclub {
      color: @sui_color_club_rosegold_dark1;
    }
    &_s3vip {
      color: #806208;
    }
  }

  &__retail-price {
    display: inline-block;
    font-size: 12px;
    color: @sui_color_gray_light1;
    text-decoration: line-through;
  }
}

</style>
