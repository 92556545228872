<template>
  <div class="product-card__price">
    <!-- 价格组件主体 -->
    <div 
      class="product-card__prices-info" 
    >
      <ProductCardNormalPrice
        v-if="showNormalPrice"
        :is-promo-price="isPromoPrice"
        :sold-out="soldOut"
        :belt-label="beltLabel"
      />

      <ProductCardPriceV2BottomLabel
        v-if="belowPriceLabel.show && priceV2LabelPosition === 'priceRight'"
      />

      <!-- 价格右侧标签 -->
      <ProductCardPriceRight />
    </div>

    <!-- 价格下方各种标签 start -->
    <div class="product-card__prices-bottom">
      <ProductCardPriceV2BottomLabel
        v-if="belowPriceLabel.show && priceV2LabelPosition === 'priceBottom'"
      />

      <!-- 价格优势组件 -->
      <PriceDiscountIntro
        v-else-if="showPriceDiscountDesc"
        :config="config"
        :historical-price="historicalPrice"
        :follow-price="followPrice"
        :bff-goods-info="goodsInfo.value.bffGoodsInfo"
      />

      <!-- vip price -->
      <section
        v-if="exclusivePromotionPrice"
        class="vip-price"
        tabindex="0"
      >
        <span>{{ exclusivePromotionPrice }}</span>
        <i
          :class="[
            'vip-price__icon',
            cssRight ? 'vip-price__icon_ar' : '',
          ]"
        ></i>
      </section>
    </div>
    <!-- 价格下方各种标签 end -->
  </div>
</template>

<script>
import ProductCardNormalPrice from './ProductCardNormalPrice.vue'
import ProductCardPriceRight from './ProductCardPriceRight.vue'
import PriceDiscountIntro from './PriceDiscountIntro.vue'
import ProductCardPriceV2BottomLabel from './ProductCardPriceV2BottomLabel.vue'

export default {
  name: 'ProductCardPricesContainer',
  components: {
    PriceDiscountIntro,
    ProductCardNormalPrice,
    ProductCardPriceV2BottomLabel,
    ProductCardPriceRight
  },
  inject: ['index', 'goodsInfo', 'finalPriceAndDiscountInfo', 'labelsFromKey', 'config', 'language', 'constantData'],
  props: {
    showSelect: {
      type: Boolean,
      default: false,
    },
    isPromoPrice: {
      type: Boolean,
      default: false
    },
    soldOut: {
      type: Boolean,
      default: true,
    },
    beltLabel: {
      type: [Object, Boolean],
      default: () => ({}),
    }
  },
  data() {
    return {
      cssRight: false
    }
  },
  computed: {
    goodsId() {
      return Number(this.goodsInfo.value?.goods_id)
    },
    goodsSn() {
      return this.goodsInfo.value?.goods_sn
    },
    exclusivePromotion() {
      return this.goodsInfo.value?.exclusivePromotionPrice || {}
    },
    // 旧版s3会员标签
    exclusivePromotionPrice() {
      // 合规站点不展示s3会员标签
      return !this.config.showS3VipPriceOnSale && !this.goodsInfo.value?.pretreatInfo?.suggestedSalePriceConfigInfo?.type && this.exclusivePromotion?.amountWithSymbol
    },
    historicalPrice () {
      return this.goodsInfo.value[this.labelsFromKey]?.historicalPrice
    },
    followPrice () {
      return this.goodsInfo.value[this.labelsFromKey]?.followPrice
    },
    showPriceDiscountDesc () {
      if ( this.hideTargetBySuggestedSalePrice ) {
        return false
      }
      const { showPriceDiscountDesc, showPriceDiscountDescInDiscountLabel, showDiscountLabelCountdownStyle } = this.config || {}

      const { estimatedPriceInfo } = this.goodsInfo.value || {}
      const { finalPriceType, priceStateCollection } = this.finalPriceAndDiscountInfo.value || {}

      // 没命中到手价倒计时展示到商品名称前，并且最低价在折扣标签里展示
      const priceDiscountDescBeforeGoodsName = this.historicalPrice?.length && showPriceDiscountDescInDiscountLabel && !(finalPriceType === 'estimatedPrice' && !priceStateCollection?.hasFlashPromotion && estimatedPriceInfo.endTime && showDiscountLabelCountdownStyle)

      return (this.historicalPrice?.length || this.followPrice) && !this.belowPriceLabel.show && showPriceDiscountDesc && !priceDiscountDescBeforeGoodsName
    },
    showNormalPrice() {
      return !this.config.hideNormalPrices
    },
    showNewStyleEstimated() {
      const { priceStateCollection } = this.finalPriceAndDiscountInfo.value || {}

      return this.config.showNewStyleEstimated && priceStateCollection?.hasEstimatedPrice && !priceStateCollection?.finalABPriceType
    },
    priceV2LabelPosition() {
      if (this.showNewStyleEstimated) {
        return 'priceBottom'
      } else {
        const priceV2LabelPosition = this.config.PriceV2LabelPosition || 'priceBottom'
        return priceV2LabelPosition
      }
    },
    belowPriceLabel() {
      if (this.hideTargetBySuggestedSalePrice) return { show: false }

      const { priceStateCollection, priceLabelText } = this.finalPriceAndDiscountInfo.value || {}

      const showEstimatedNewStyle = this.config.showNewStyleEstimated && priceStateCollection?.hasEstimatedPrice && !priceStateCollection?.finalABPriceType

      return {
        show: showEstimatedNewStyle || priceLabelText
      }
    },
    hideTargetBySuggestedSalePrice() {
      const { suggestedSalePriceData } = this.finalPriceAndDiscountInfo.value || {}

      return suggestedSalePriceData?.config?.hideTargetBySuggestedSalePrice
    }
  },
  created() {
    this.cssRight = this.constantData.GB_cssRight

  }
}
</script>

<style lang="less">
.product-card__prices-info {
  & > div:not(.normal-price-ctn__prices), & > p {
    margin-right: 6px;
  }
}
</style>

<style lang="less" scoped>
@s3_exclusive: '/she_dist/images/sheinvip2/s3_exclusive-3286f50076.png';
@s3_exclusive_ar: '/she_dist/images/sheinvip2/s3_exclusive_ar-7c299f990d.png';


.product-card {
  /* stylelint-disable-next-line block-no-empty */
  &__price {
    min-width: 0;
  }
  &__prices-info {
    .flexbox();

    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    &_camel {
      height: 23px;
    }
  }
  &__prices-bottom {
    width: fit-content;
  }

  .price-overflow-hidden {
    overflow: hidden;
  }

  .price-height-21 {
    height: 21px;
  }
}

// 付费会员 start
.shein-club {
  .flexbox();
  .align-center();
  flex-wrap: wrap;
  margin-top: 4px;
  &__prime-price-ctn {
    .flexbox();
    .align-center();
  }
  &__logo {
    width: 15px;
    margin-right: 3px;
  }
  &__prime-price {
    font-size: 14px;
    line-height: 16px;
    margin-right: 4px;
    color: #ffba97;
    font-weight: bold;
  }
  &__sale-price {
    font-size: 12px;
    line-height: 14px;
    color: @sui_color_gray_light1;
    text-decoration: line-through;
  }
}
// 付费会员 end

// vip price start
.vip-price {
  margin-top: 4px;
  padding-bottom: 12px;
  height: 14px;
  .txt-l();

  > span {
    font-size: 12px;
    font-weight: bold;
    color: #a78a45;
  }

  &__icon {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 34px 10px;
    width: 34px;
    height: 10px;
    background-image: url(@s3_exclusive);

    &_ar {
      background-image: url(@s3_exclusive_ar);
      vertical-align: -1px;
    }
  }
}
.rw-vip-price {
  margin-top: 4px;
  padding-bottom: 4px;
}

.last-price {
  font-size: 12px;
  line-height: 12px;
  color: #fff;
  transform: scale(0.84);
}
</style>
