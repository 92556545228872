import { daEventCenter } from 'public/src/services/eventCenter/index'

// 点击add to bag
const clickAddToBagAna = ({ target, config }) => {
  daEventCenter.triggerNotice({
    daId: '2-3-5', //click_goods_list_addcar
    target,
    extraData: {
      config
    }
  })
}

// 点击find similar按钮
const clickFindSimilarAna = ({ goodsInfo, config, soldOut }) => {
  daEventCenter.triggerNotice({
    daId: '1-6-1-3',
    extraData: {
      sku: goodsInfo.goods_sn,
      goods_id: goodsInfo.goods_id,
      activeFrom: config.from,
      findsimilar_method: soldOut ? 'SoldOut' : 'ClickMore', //  售空 SoldOut，无售空 ClickMore
      isOutOfStock: soldOut ? 1 : 0,
    },
  })
}

// 曝光find similar按钮
const exposeFindSimilarAna = ({ goodsInfo, config, soldOut }) => {
  daEventCenter.triggerNotice({
    daId: '1-6-1-2',
    extraData: {
      sku: goodsInfo.goods_sn,
      goods_id: goodsInfo.goods_id,
      activeFrom: config.from,
      isOutOfStock: soldOut ? 1 : 0,
    },
  })
}

// 曝光加组按钮
const exposeAddToBoardAna = ({ groupList }) => {
  daEventCenter.triggerNotice({
    daId: '1-4-1-8',
    extraData: { board_count: groupList.length ? 1 : 0 },
  })
}

// 曝光赛马标签
const exposeHorseRaceLabel = ({ targets, goods_id, rankingListAna }) => {
  const prd_info = goods_id + ''
  const label_info = targets?.map(target => {
    const type = target && target.getAttribute('data-type') || ''
    const tagId = target && target.getAttribute('data-tag-id') || '-'
    const tagIndex = target && target.getAttribute('data-tag-index') || ''
    const tagContent = target && target.getAttribute('data-tag-content') || ''
    const appTraceInfo = target && target.getAttribute('data-tag-appTraceInfo') || ''

    if (type === 'ranking') {
      return '-'
    }

    if (appTraceInfo) {
      return appTraceInfo
    } else {
      // 标签id`标签展示坑位数`标签文案
      return `${tagId}\`${tagIndex}\`${tagContent.replace(/\s/g, '')}`
    }
  }).join(',')

  daEventCenter.triggerNotice({
    daId: '2-3-42',
    extraData: {
      prd_info,
      label_info,
      ranking_list_module: rankingListAna ? 'ranking_list_label' : '-',
      ranking_list_identifier: rankingListAna || '-'
    }
  })
}

export {
  clickAddToBagAna,
  clickFindSimilarAna,
  exposeFindSimilarAna,
  exposeAddToBoardAna,
  exposeHorseRaceLabel
}
