<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div
    v-if="displayList?.length > 0"
    class="product-labels-carousel"
    :style="{
      '--label-height': `${height}px`,
      '--icon-size': `${iconSize}px`,
      '--text-color': color,
      '--text-width': `${textWidth}px`,
      '--text-top': `${offsetTop}px`
    }"
  >
    <SwiperSlide
      class="product-labels-wraper"
      :autoplay="3000"
      :first-autoplay="1500"
      :transition-duration="300"
      :item-length="displayList?.length || 0"
      :delay-loop-init-time="1500"
      :touchable="false"
      :prevent-default="false"
      direction="vertical"
      intersection-observer
    >
      <SwiperSlideItem
        v-for="(item, i) in displayList"
        :key="i"
        :index="i"
        stop-propagation
      >
        <div class="product-labels-item">
          <span class="labels-item-text">{{ item.text }}</span>
        </div>
      </SwiperSlideItem>
    </SwiperSlide>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { getProductLabbels } from './logic.js'
import SwiperSlide from 'public/src/pages/components/Swiper/Swiper.vue'
import SwiperSlideItem from 'public/src/pages/components/Swiper/SwiperItem.vue'

const props = defineProps({
  color: {
    type: String,
    default: 'rgba(101, 30, 0, 0.5)'
  },
  textWidth: {
    type: Number,
    default: 150
  },
  iconSize: {
    type: Number,
    default: 12
  },
  iconColor: {
    type: String,
    default: '#E3BE95'
    // default: '#FFD8C4'
  },
  userPay: {
    type: Array,
    default: () => []
  },
  userIcon: {
    type: String,
    default: 'user'
  },
  height: {
    type: Number,
    default: 12
  },
  offsetTop: {
    type: Number,
    default: 0
  },
  sortLabels: {
    type: Array,
    default: () => [
      '3526',
      '3527',
      '1661',
      '207',
      '3460',
      '3457',
      '3726',
      '3454'
    ]
  },
  data: {
    // Object-商品轮播，Array-榜单头部轮播
    type: [Object, Array],
    default: () => {
      return {}
    }
  },
  language: {
    type: Object,
    default: () => {
      return {}
    }
  }
})
const emit = defineEmits(['change'])
const displayList = computed(() => {
  const { data, language, iconColor, userIcon, sortLabels } = props
  const arr = getProductLabbels(data, language, {
    iconColor,
    userIcon,
    sortLabels
  })
  emit('change', arr)
  return arr
})
</script>
<style lang="less">
.product-labels-carousel,
.product-labels-wraper,
.product-labels-item {
  height: var(--label-height, 12px);
}
.product-labels-carousel {
  overflow: hidden;
  width: 100%;
  // border: 1px solid red;
  .product-labels-item {
    display: flex;
    align-items: center;
    color: var(--text-color);
    overflow: hidden;
    width: 100%;
  }
  .labels-item-icon {
    width: var(--icon-size);
    height: var(--icon-size);
    // border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2px;
    flex-shrink: 0;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .labels-item-text {
    // border:1px solid red;
    // flex:1;
    // height: var(--label-height);
    // align-self: center;
    // padding-top: 1px;
    margin-top: var(--text-top);
    align-self: stretch;
    line-height: var(--label-height);
    width: var(--text-width, 200px);
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
    color: var(--text-color);
  }
}
</style>
