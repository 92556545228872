<template>
  <!-- 价格右侧 区域 -->
  <!-- 折扣标签 -->
  <DiscountLabelV2 
    v-if="showDiscountValue.show"
    position="price"
  />

  <!-- 销量标签 -->
  <div 
    v-if="salesLabels && salesLabels.labelLang"
    :style="salesLabelsStyle"
    class="product-card__sales-label"
    :class="{
      'sales-label-hot': salesLabels.icon
    }"
  >
    <img 
      v-if="salesLabels.icon" 
      class="sales-label__icon"
      :src="salesLabels.icon" 
    />
    {{ salesLabels.labelLang }}
  </div>
</template>

<script name="ProductCardPriceRight" setup>
import DiscountLabelV2 from '../common/DiscountLabelV2.vue'

import { inject, computed } from 'vue'
import { labelTypeMap } from '../../utils/constant.js'

const labelsFromKey = inject('labelsFromKey')
const config = inject('config', () => { return { } })

const setAttrForAnalysis = inject('setAttrForAnalysis')

const goodsInfo = inject('goodsInfo', () => { return { } })
const finalPriceAndDiscountInfo = inject('finalPriceAndDiscountInfo', () => { return { } })


const { showDiscountLabelAfterPrice } = config

const hideDiscount = computed(() => {
  return goodsInfo.value?.pretreatInfo?.suggestedSalePriceConfigInfo?.config?.hideDiscount
})


// 折扣标签信息
const showDiscountValue = computed(() => {
  const { finalDiscount, isSpecialDeSuggested, priceStateCollection } = finalPriceAndDiscountInfo.value || {}
  const defaultValue = {
    show: false
  }
  
  if (showDiscountLabelAfterPrice && !hideDiscount.value && !isSpecialDeSuggested && !priceStateCollection?.hideDiscountLabel) return finalDiscount
  
  return defaultValue
})

// 销量标签
const salesLabels = computed(() => {
  const hasOwnPropertyShowSalesLabel = config.hasOwnProperty('showSalesLabel')
  if (!hasOwnPropertyShowSalesLabel || (hasOwnPropertyShowSalesLabel && config.showSalesLabel !== false)) {
    return goodsInfo.value?.[labelsFromKey]?.salesLabels ?? {}
  } else {
    return {}
  }
})

const salesLabelsStyle = computed(() => {
  return {
    color: salesLabels.value?.fontColor || '', 
    backgroundColor: salesLabels.value?.backgroundColor || ''
  }
})

if (salesLabels.value?.labelLang) {
  setAttrForAnalysis((el) => {
    const anaValue = `show_sales_label_${labelTypeMap.get(salesLabels.value.textType)}_${salesLabels.value.labelId}_${salesLabels.value.labelLang?.replace(/\s+/g, '_')}`
    el.setAttribute('data-sales-label', salesLabels.value?.appTraceInfo || anaValue)
  })
}

</script>

<style lang="less" scoped>
.product-card__sales-label {
  .flexbox();
  .align-center();
  
  font-size: 14px;
  color: var(--sui-color-gray-dark-3, #767676);
  .sales-label {
    &__icon {
      width: 14px;
      height: 14px;
      margin-right: 2px;
    }
  }
}
</style>
