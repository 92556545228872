<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div
    v-if="displayList?.length > 0"
    class="product-labels-carousel bff-product-labels-carousel"
  >
    <SwiperSlide
      class="product-labels-wraper"
      :autoplay="3000"
      :first-autoplay="1500"
      :transition-duration="300"
      :item-length="displayList?.length || 0"
      :delay-loop-init-time="1500"
      :touchable="false"
      :prevent-default="false"
      direction="vertical"
      intersection-observer
    >
      <SwiperSlideItem
        v-for="(item, i) in displayList"
        :key="i"
        :index="i"
        stop-propagation
      >
        <div class="product-labels-item">
          <span class="labels-item-text">{{ item.labelLang }}</span>
        </div>
      </SwiperSlideItem>
    </SwiperSlide>
  </div>
</template>

<script name="BffProductLabelCarousel" setup>
import { computed, toRef } from 'vue'
import SwiperSlide from 'public/src/pages/components/Swiper/Swiper.vue'
import SwiperSlideItem from 'public/src/pages/components/Swiper/SwiperItem.vue'

const props = defineProps({
  goodsInfo: {
    type: Object,
    default: () => {
      return {}
    }
  },
  language: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const goodsInfo = toRef(props, 'goodsInfo')

const displayList = computed(() => {
  const popularLabelList = goodsInfo.value?.pretreatInfo.popularLabelList || []

  const displayList = popularLabelList.filter(v => !!v.labelLang)

  return displayList
})
</script>

<style lang="less">

.product-labels-carousel,
.product-labels-wraper,
.product-labels-item {
  height: var(--label-height, 14px);
}
.product-labels-carousel {
  overflow: hidden;
  width: 100%;
  .product-labels-item {
    display: flex;
    align-items: center;
    color: var(--text-color, #A86104);
    overflow: hidden;
    width: 100%;
  }
  .labels-item-icon {
    width: var(--icon-size, 12px);
    height: var(--icon-size, 12px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2px;
    flex-shrink: 0;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .labels-item-text {
    margin-top: var(--text-top, 0.5px);
    align-self: stretch;
    line-height: var(--label-height, 14px);
    width: var(--text-width, 200px);
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
    color: var(--text-color, #A86104);
  }
}
</style>
